import React from 'react'
import {  Routes, Route, Navigate, Outlet } from "react-router-dom"
import { Footer } from './components/Footer/Footer'
import { Header } from './components/Header/Header'
import { GlobalStyle } from './styles/global'
import HomePage from './components/HomePage/HomePage'
import Project from './pages/Project/Project'
import 'react-toastify/dist/ReactToastify.css'

function Layout() {
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

function App() {
  return (
    <>
      <GlobalStyle />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="project" element={<Project />} />
          {/* Add more nested routes here as needed */}
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

    </>
  )
}

export default App
