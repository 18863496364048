import { Container } from './styles'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import { useState } from 'react'
import Resume from '../../assets/Terry_Taylor_Resume.pdf'

export function Header() {
  const [menuActive, setMenuActive] = useState(false)
  
  function toggleTheme() {
    let html = document.getElementsByTagName('html')[0]
    html.classList.toggle('light')
  }

  function closeMenu() {
    setMenuActive(false)
  }

  return (
    <Container className="header-fixed">
      <HashLink smooth to="#home" className="logo">
        <span>{"<Terry "}</span>
        <span>{" Taylor/>"}</span>
      </HashLink>
      <input
        onChange={toggleTheme}
        className="container_toggle"
        type="checkbox"
        id="switch"
        name="mode"
      />
      <label htmlFor="switch">Toggle</label>
      <nav className={menuActive ? 'active' : ''}>
        <NavHashLink smooth to="#home" onClick={closeMenu}>
          Home
        </NavHashLink>
        <NavHashLink smooth to="#about" onClick={closeMenu}>
          About me
        </NavHashLink>
        <NavHashLink smooth to="#project" onClick={closeMenu}>
          Project
        </NavHashLink>
        <NavHashLink smooth to="#contact" onClick={closeMenu}>
          Contact
        </NavHashLink>
        <a href={Resume} download className="button">
          Resume
        </a>
      </nav>
      <div
        aria-expanded={menuActive ? 'true' : 'false'}
        aria-haspopup="true"
        aria-label={menuActive ? 'Close menu' : 'Open menu'}
        className={menuActive ? 'menu active' : 'menu'}
        onClick={() => {
          setMenuActive(!menuActive)
        }}
      ></div>
    </Container>
  )
}
