
import React, { useState, useEffect } from 'react'
import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"
import Illustration from "../../assets/illustration.svg"
import { NavHashLink } from "react-router-hash-link"
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
//import whatsapp from '../../assets/whatsapp.svg'
import Hello from '../../assets/Hello.gif'
//import telegram from '../../assets/telegram.svg'
export function Hero() {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [monthsDifference, setMonthsDifference] = useState(0);
  useEffect(() => {
    const startDate = new Date("2021-01-01");
    const currentDate = new Date();
    const yearDiff = currentDate.getFullYear() - startDate.getFullYear();
    const monthDiff = currentDate.getMonth() - startDate.getMonth();
    const totalMonthsDifference = yearDiff * 12 + monthDiff;
    setMonthsDifference(totalMonthsDifference);
    setCurrentYear(currentDate.getFullYear());
  }, []);
  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
          <p>Hello <img src={Hello} alt="Hello" width="20px"/>, I'm</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Terry Taylor</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Full Stack Developer</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">{(monthsDifference / 12).toFixed(1)} Years of Experience</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>

            <NavHashLink smooth to="#contact" className="button">Contact</NavHashLink>
     
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
      <div className="social-media"><a
        href="https://www.linkedin.com/in/terry-taylor-3022071b9/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedin} alt="Linkedin" />
      </a>
        <a
          href="https://github.com/terrible-terry"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>
      </div>
        </ScrollAnimation>
      </div>
      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img src={Illustration} alt="Ilustração" />
        </ScrollAnimation>
      </div>
    </Container>
  )
}