
import React, { useState, useEffect } from 'react'

import { Main } from '../Main/Main';

export default function Project_1() {


  useEffect(() => {
console.log("f");
  }, []);
  return (
  <>
  <Main></Main>
  </>
  )
}